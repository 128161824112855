var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"band"},[_c('Modal',{attrs:{"visible":_vm.modal_politics},on:{"close":function($event){_vm.modal_politics = false}}}),_c('v-card',{staticClass:"position-relative",attrs:{"elevation":"0","tile":""}},[(_vm.band.background !== null)?[(_vm.band.background.image)?[_c('v-img',{staticClass:"band__background",attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads' +
            _vm.band.background.path}})]:(_vm.band.background.video == true)?[_c('video',{staticClass:"band__background",staticStyle:{"object-fit":"cover"},attrs:{"autoplay":"","muted":"","loop":"","height":"100%","width":"100%","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads' +
              _vm.band.background.path,"type":"video/mp4"}})])]:_vm._e()]:_vm._e(),_c('v-container',{staticClass:"position-relative px-4 px-sm-12",staticStyle:{"height":"100vh"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"end","justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"auto"}},[_c('div',[(_vm.band.cover.cover_img != null)?[(_vm.band.cover.cover_img.image == true)?[(_vm.band.cover.cover_link != '')?[_c('a',{attrs:{"href":_vm.band.cover.cover_link,"target":"_blank"}},[_c('v-img',{staticClass:"band__img-cover",attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                        _vm.band.cover.cover_img.path,"contain":""}})],1)]:[_c('v-img',{staticClass:"band__img-cover",attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                      _vm.band.cover.cover_img.path,"contain":""}})]]:(_vm.band.cover.cover_img.video == true)?[(_vm.band.cover.cover_link != '')?[_c('a',{attrs:{"href":_vm.band.cover.cover_link,"target":"_blank"}},[_c('video',{staticClass:"band__img-cover",attrs:{"autoplay":"","loop":"","mute":"","playsinline":""}},[_c('source',{attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                          _vm.band.cover.cover_img.path,"type":"video/mp4"}})])])]:[_c('video',{staticClass:"band__img-cover",attrs:{"autoplay":"","loop":"","mute":"","playsinline":""}},[_c('source',{attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/storage/uploads/' +
                        _vm.band.cover.cover_img.path,"type":"video/mp4"}})])]]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"band__box-btns-texts text-center text-sm-left"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.band.texts_btns),function(text_btn,tb){return _c('v-col',{key:tb,staticClass:"mb-5 mb-sm-0",class:{'padding-right-custom': _vm.band.texts_btns.length > 1},attrs:{"cols":"12","sm":"auto"}},[(text_btn.value.call_to_action !== null)?[_c('a',{class:{'white--text': _vm.band.color_texts == 'blanco', 'black--text': _vm.band.color_texts == 'negro'},attrs:{"href":text_btn.value.call_to_action,"target":"_blank"}},[_c('span',{staticClass:"texts inter-bold",class:{'white--text': _vm.band.color_texts == 'blanco', 'black--text': _vm.band.color_texts == 'negro'}},[_vm._v(_vm._s(text_btn.value.text))])])]:[_c('span',{staticClass:"texts inter-bold",class:{'white--text': _vm.band.color_texts == 'blanco', 'black--text': _vm.band.color_texts == 'negro'}},[_vm._v(_vm._s(text_btn.value.text))])]],2)}),1)],1),_c('div',{staticClass:"band__box-btns-social-networks"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.band.social_networks),function(social_media,sc){return _c('v-col',{key:sc,staticClass:"band__box-btns-social-networks__column",class:{'padding-right-custom': social_media.length == 0},attrs:{"cols":"auto"}},[(social_media.value.icon !== null)?[_c('a',{attrs:{"href":social_media.value.call_to_action,"target":"_blank"}},[_c('v-img',{staticClass:"band__box-btns-social-networks__column--icon",attrs:{"src":'https://www.landing-xonoro-cms.actstudio.xyz/' +
                        social_media.value.icon.path,"contain":""}})],1)]:_vm._e()],2)}),1)],1)]),_c('v-col',{staticClass:"mb-1 mb-sm-4",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"line-height-custom-521",attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"rewrite-font inter-bold",class:{'white--text': _vm.band.color_texts == 'blanco', 'black--text': _vm.band.color_texts == 'negro'}},[_c('span',[_vm._v("Powered by")]),_vm._v(" "),_c('span',{staticClass:"inter-bold powered-padding"},[_vm._v("Xonoro + ACT.")])])]),_c('v-col',{staticClass:"margin-top-custom",attrs:{"cols":"12"}},[_c('span',{staticClass:"rewrite-font",class:{'white--text': _vm.band.color_texts == 'blanco', 'black--text': _vm.band.color_texts == 'negro'}},[_vm._v("Para más detalles revisa nuestra "),_c('span',{staticClass:"underline cursor-pointer",on:{"click":function($event){_vm.modal_politics = true}}},[_vm._v("política de privacidad")])])])],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }